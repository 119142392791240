<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课酬明细</span>
    </div>
    <!-- <el-row>
      <date-picker ref="dataPicker" :dateTime="dateTime" @updateView="datetTimeGet" class="mb-10"></date-picker>
      <el-button class="tb-button" @click="search" type="primary" size="small">查 询</el-button>
    </el-row> -->
    <el-table
      :data="dataList"
      tooltip-effect="dark"
      border
      style="width:100%">
      <el-table-column label="上课时间" align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.startTime | formatDateStart}} {{scope.row.endTime | formatDateEnd}}</span>
        </template>
      </el-table-column>
      <el-table-column label="班级名称" prop="classTitle" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="班级校区" prop="classCampusTitle" :show-overflow-tooltip="true" align="center"></el-table-column>
      <el-table-column label="课时" align="center" prop="lessonLong">
        <template slot-scope="scope">
          <span v-text="changeHourMinutestr(scope.row.lessonLong)"></span>
        </template>
      </el-table-column>
      <el-table-column label="出勤人数" align="center" prop="attend"></el-table-column>
      <el-table-column label="课堂角色" align="center" prop="lessonRole"></el-table-column>
      <!-- <el-table-column label="课时费" align="center" prop="salaryLong"></el-table-column>
      <el-table-column label="课系费" align="center" prop="salaryLesson"></el-table-column> -->
      <el-table-column label="小计（元）" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.salaryTotal === null">0</span>
          <span v-else>{{scope.row.salaryTotal}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="this.pagination.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import DatePicker from '@/components/date-picker'
import { getListSalary } from '@/api/salary'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    ssid: {
      default: '',
      type: String,
      require: true
    },
    userId: {
      default: '',
      type: String,
      require: true
    },
    formData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      dateTime: [],
      minStartTime: '',
      maxStartTime: '',
    }
  },
  components: {DatePicker},
  methods: {
    datetTimeGet(data) {
      if(data === null) {
        this.minStartTime = ''
        this.maxStartTime =  ''
      } else {
        this.minStartTime = data[0]
        this.maxStartTime =  data[1]
      }
    },

    openDialog() {
      this.pagination.currentPage = 1
      this.getListSalary()
    },

    preview(row) {
      this.close()
      this.$emit('historyParams', row.param)
    },

    search() {
      this.pagination.currentPage = 1
      this.getListSalary()
    },

    changeHourMinutestr(str) {
      if (str % 60 === 0) {
        return str / 60
      } else {
        return Number(str / 60).toFixed(2)
      }
    },

    getListSalary() {
      getListSalary({
        ssid: this.ssid,
        userId: this.userId,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        campusIds: this.formData.campusIds ? this.formData.campusIds : null,
        classIdsExclude: this.formData.classIdsExclude ? this.formData.classIdsExclude : null
      }).then(res => {
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      })
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getListSalary()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.getListSalary()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/style/dialog.scss";
</style>
