<template>
  <div class="page-container">
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="12" :md="24">
            <el-form-item label="选择校区：">
              <el-select v-model="formData.campusIds" multiple filterable placeholder="请选择" class="w100" clearable disabled>
                <el-option
                  v-for="item in campusIdsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="过滤班级：">
              <el-select
                v-model="formData.classIdsExclude"
                multiple
                filterable
                clearable
                disabled
                placeholder="请选择"
                class="w100"
              >
                <el-option
                  v-for="item in classIdsExcludeList"
                  :key="item.class_id"
                  :label="item.name"
                  :value="item.class_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
          </template>
          <!-- <search-query
            @updateView="updateView"
            @clearQuery="formData = { };search()"
            @searchQuery="search"
            :showMore="false"
            queryText="统 计"
          ></search-query> -->
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <!-- <span></span> -->
        <div class="flex_1"></div>
        <download-excel :data="dataList" :fields="json_fields" name="教师课酬统计列表.xls">
          <el-button type="primary" size="mini" style="margin-right: 10px;">导出报表</el-button>
        </download-excel>
        <!-- <el-button plain size="mini" @click="historyReport()"
          >历史报表</el-button
        > -->
        <!-- <el-button
          plain
          size="mini"
          @click="saveSumSalaryParam"
          >保存当前报表</el-button
        > -->
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '总薪酬'">
                <span class="column-link" @click="salaryDetail(scope.row)">{{scope.row.salaryTotal}}</span>
              </template>
              <template v-else-if="item.label === '总课时'">
                <span v-text="changeHourMinutestr(scope.row.lessonLong)"></span>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

      <!-- 历史报表 -->
      <history-report :show="reportDialog" @close="reportDialog=false" @historyParams="historyParams"></history-report>

      <!-- 课酬明细 -->
      <salary-detail :show="salaryDialog" @close="salaryDialog=false" :ssid="ssid" :userId="userId" :formData="formData"></salary-detail>
    </el-card>
  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import { getCampusAll } from '@/api/senate/campus'
  import { getSumSalary, saveSumSalaryParam } from '@/api/salary'
  import { getClassAll } from '@/api/senate/class'
  import HistoryReport from '@/components/history-report'
  import JsonExcel from 'vue-json-excel'
  import SalaryDetail from '@/components/salary-detail'

  // 默认展示列
  const baseColSetting = [
    {
      label: '员工姓名',
      prop: 'userName',
      state: true,
      align: 'center',
      width: '130',
    },
    {
      label: '工号',
      prop: 'loginName',
      state: true,
      align: 'center',
      width: '120',
    },
    {
      label: '总课时',
      prop: 'lessonLong',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '总出勤人数',
      prop: 'attend',
      state: true,
      align: 'center',
      width: '100',
    },
    // {
    //   label: '总课时费',
    //   prop: 'salaryLong',
    //   state: true,
    //   align: 'center',
    //   width: '100',
    // },
    // {
    //   label: '总课系费',
    //   prop: 'salaryLesson',
    //   state: true,
    //   align: 'center',
    //   width: '100',
    // },
    {
      label: '总薪酬',
      prop: 'salaryTotal',
      state: true,
      align: 'center',
      width: '100',
    },
  ]
  export default {
    components: { TextButton, SearchQuery, HistoryReport, downloadExcel: JsonExcel, SalaryDetail },
    created() {
      let datas = JSON.parse(this.$route.query.param)
      if (datas.campusIds && datas.campusIds.lenght !== 0) {
        this.formData.campusIds = datas.campusIds
      }
      if (datas.classIdsExclude && datas.classIdsExclude.lenght !== 0) {
        this.formData.classIdsExclude = datas.classIdsExclude
      }
      this.isProps = false
      this.getCampusAll()
      this.getClassAll()
      this.getSumSalary(datas)
    },
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },
    data() {
      return {
        dataList: [],
        tableKey: 1,
        queryParam: {
          // 查询参数
          isFinish: false,
        },
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 50, 100, 200, 400],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        advanced: false,
        formData: {},
        campusIdsList: [],
        classIdsExcludeList: [],
        reportDialog: false,
        json_fields: {
          '员工姓名': 'userName',
          '工号': 'loginName',
          '总课时': 'lessonLong',
          '总出勤人数': 'attend',
          // '总课时费': 'salaryLong',
          // '总课系费': 'salaryLesson',
          '总薪酬': 'salaryTotal',
        },
        salaryDialog: false,
        ssid: '',
        userId: '',
        isProps: false,
      }
    },
    methods: {
      async getCampusAll () {
        const res = await getCampusAll()
        this.campusIdsList = res.body
      },

      historyParams(data) {
        let datas = JSON.parse(data)
        if (datas.campusIds && datas.campusIds.lenght !== 0) {
          this.formData.campusIds = datas.campusIds
        }
        if (datas.classIdsExclude && datas.classIdsExclude.lenght !== 0) {
          this.formData.classIdsExclude = datas.classIdsExclude
        }
        this.isProps = true
        this.getSumSalary(data)
      },

      changeHourMinutestr(str) {
        if (str % 60 === 0) {
          return str / 60
        } else {
          return Number(str / 60).toFixed(2)
        }
      },


      historyReport() {
        this.reportDialog = true
      },

      search() {
        this.isProps = true
        this.pagination.currentPage = 1
        this.getSumSalary()
      },

      async getClassAll () {
        const res = await getClassAll({
          pageNum: 1,
          pageSize: 1000,
          campusIdSet: this.formData.campusIds,
          minLessonTime: this.$route.query.min,
          maxLessonTime: this.$route.query.max,
          needCount: true
        })
        this.classIdsExcludeList = res.body.list
      },

      async getSumSalary(data) {
        if (!data) {
          const res = await getSumSalary({
            ssid: this.$route.query.id,
            pageNum: this.pagination.currentPage,
            pageSize: this.pagination.pageSize,
            classIdsExclude: this.formData.classIdsExclude ? this.formData.classIdsExclude : null,
            campusIds: this.formData.campusIds ? this.formData.campusIds : null
          })
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        } else {
          const res = await getSumSalary(data)
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        }
      },

      updateView(data) {
        this.advanced = data
      },

      update(data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getSumSalary()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getSumSalary()
      },

      // 课酬详情
      salaryDetail(row) {
        this.ssid = row.ssid
        this.userId = row.userId
        this.salaryDialog = true
      },

      // 保存报表
      async saveSumSalaryParam() {
        await saveSumSalaryParam({
          ssid: this.$route.query.id,
          classIdsExclude: this.formData.classIdsExclude ? this.formData.classIdsExclude : null,
          campusIds: this.formData.campusIds ? this.formData.campusIds : null
        })
        window.$msg('保存成功')
      },
    },

  }
</script>

<style lang="scss" scoped>
@import '~@/style/container.scss';

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
